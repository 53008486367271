import { _axios } from '@/utils/axios'

export function newOrder(data) { // 生成订单
  return _axios({
    url: '/carpool/ns/orders',
    method: 'post',
    data
  })
}

export function purchase(data) { // 下单
  return _axios({
    url: `/carpool/ns/orders/${data.orderId}`,
    method: 'put',
    data
  })
}

export function upgradePurchase(data) { // 升级下单
  return _axios({
    url: `/carpool/ns/orders/${data.orderId}`,
    method: 'put',
    data
  })
}


export function getOrderInfo(params) {
  if (params) {
    params.random = Math.random()
  }
  return _axios({
    url: `/orders/${params.orderId}`,
    method: 'get',
    params
  })
}


export function refund(data) { // 退款
  return _axios({
    url: `/carpool/ns/orders/refund`,
    method: 'post',
    data
  })
}
