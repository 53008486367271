
<template>
  <div class="zhimafen-mask" v-if="display">
    <div class="containner">
      <div class="zhimafen-wrap">
        <div class="zhimafen">
          <div class="tip" v-if="type === 'screenshot'">确认过芝麻，是彼此信任的靠谱车友~</div>
          <div class="tip" v-else>打开「支付宝」-「我的」-「芝麻信用」，然后进行截图上传~</div>
          <img :src="url" />
          <div class="close" @click="close"></div>
        </div>
        <div class="close-btn" @click="close">关闭查看</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    display: {
      type: Boolean,
      default: false
    },
    url: {
      type: String
    },
    type: {
      type: String,
      default: 'screenshot'
    },
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
<style scoped lang="less">
.zhimafen-mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.65);
  .containner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    .zhimafen-wrap {
      position: relative;
      .zhimafen {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 5.68rem;
        height: 9.02rem;
        border-radius: 0.12rem;
        background: #FFFFFF;
        .tip {
          margin-left: 0.24rem;
          margin-right: 0.24rem;
          margin-bottom: 0.12rem;
          color: #4F4F52;
          font-size: .24rem;
        }
        img {
          width: 3.66rem;
          height: 7.94rem;
          pointer-events: none;
        }
      }
      .close-btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 0.28rem;
        background: #53CCA8;
        width: 2.72rem;
        height: 0.76rem;
        border-radius: 0.38rem;
        position: absolute;
        bottom: -.54rem;
        margin: auto;
        left: 0;
        right: 0;
      }
      .close {
        cursor: pointer;
        top: -0.7rem;
        right: -0.38rem;
        position: absolute;
        width: 0.5rem;
        height: 0.5rem;
        background: white;
        border-radius: 50%;
        &::before,
        &::after {
          content: "";
          position: absolute;  /*方便进行定位*/
          height: .3rem;
          width: 0.06rem;
          border-radius: 0.04rem;
          top: 0.1rem;
          right: 0.21rem;  /*设置top和right使图像在20*20框中居中*/
          background: rgba(0, 0, 0, 0.65);
        }
        &::before {
          transform: rotate(45deg);  /*进行旋转*/
        }
        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }
}
</style>