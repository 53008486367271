import { _axios } from '@/utils/axios'

export function getPlans(params) {
  return _axios({
    url: '/carpool/ns/plans',
    method: 'get',
    params
  })
}

export function getNotify(params) {
  return _axios({
    url: '/carpool/ns/notify',
    method: 'get',
    params
  })
}

export function getInstantOrder(params) {
  if (params) {
    params.random = Math.random()
  }
  return _axios({
    url: '/carpool/ns/instantOrder',
    method: 'get',
    params
  })
}

export function getZhimache(params) {
  if (params) {
    params.random = Math.random()
  }
  return _axios({
    url: '/carpool/ns/zhimache',
    method: 'get',
    params
  })
}

export function getCharteredCars(params) {
  if (params) {
    params.random = Math.random()
  }
  return _axios({
    url: '/carpool/ns/charteredCars',
    method: 'get',
    params
  })
}

export function getCharteredCarInfo(params) {
  if (params) {
    params.random = Math.random()
  }
  return _axios({
    url: `/carpool/ns/charteredCars/${params.carId}`,
    method: 'get',
    params
  })
}

export function chartedCarAddPassenger(data) {
  return _axios({
    url: `/carpool/ns/charteredCars/${data.carId}`,
    method: 'post',
    data
  })
}

export function addChartedCarPassenger(data) {
  return _axios({
    url: `/carpool/ns/charteredCars/${data.carId}`,
    method: 'put',
    data
  })
}

export function updateChartedCarPassenger(data) {
  return _axios({
    url: `/carpool/ns/charteredCars/${data.carId}`,
    method: 'post',
    data
  })
}

export function updatePassengerStatus(data) { // 修改乘客状态
  return _axios({
    url: `/carpool/ns/passager/status`,
    method: 'post',
    data
  })
}

export function upgradePlan(data) { // 套餐升级
  return _axios({
    url: `/carpool/ns/plan`,
    method: 'post',
    data
  })
}

export function checkStatus(params) {
  return _axios({
    url: `/carpool/ns/orders/${params.orderId}`,
    method: 'get',
    params
  })
}

