<template>
  <div class="plans-mask" v-if="display">
    <div class="containner">
      <div class="blank"></div>
      <div class="plans-wrap">
        <div class="product">
          <img src="../assets/product.png" />
          <div class="sku">
            <div class="name">
              {{
                plans.length
                  ? plans[planActIdx].skuTypeName
                    ? plans[planActIdx].skuTypeName.indexOf('高级') !== -1
                      ? 'NS高级家庭会员'
                      : 'NS普通家庭会员'
                    : 'NS家庭会员'
                  : 'NS家庭会员'
              }}
            </div>
            <div class="duration">
              {{ plans.length ? plans[planActIdx].label : '' }}
            </div>
          </div>
          <div class="close-icon-wrap" @click="close">
            <div class="close-icon"></div>
          </div>
        </div>
        <div
          style="
            font-size: 0.28rem;
            display: flex;
            width: 7.02rem;
            height: 0.5rem;
            color: #1f2021;
          "
        >
          <div>选择时长</div>
        </div>
        <div class="plans" :class="{ 'is-mobile': !isPC }">
          <div class="empty">&nbsp;</div>

          <div
            class="plan-wrapper-mask"
            :class="{
              advance: plans[planActIdx].skuTypeName.indexOf('高级') !== -1
            }"
          >
            <div
              class="plan-wrapper"
              :class="{
                advance: plans[planActIdx].skuTypeName.indexOf('高级') !== -1
              }"
            >
              <img
                class="plan-logo"
                style="width: 3.04rem; height: 2rem"
                src="../assets/plan-logo.png"
              />
              <div
                class="plan-label"
                :class="{
                  advance: plans[planActIdx].skuTypeName.indexOf('高级') !== -1
                }"
              >
                ONLINE
              </div>

              <div
                class="plan"
                :class="{
                  advance: plans[planActIdx].skuTypeName.indexOf('高级') !== -1
                }"
                @click="planSelect(idx)"
                :key="idx"
                v-for="(plan, idx) in plans"
              >
                <div class="label" v-if="plan.label">{{ plan.label }}</div>
                <div class="unit">{{ plan.name }}</div>
                <div class="price">
                  <span class="currency">￥</span>
                  {{ (plan.price * plan.discount).toFixed(1) }}
                </div>
                <div class="discount" v-if="plan.discount != 1">
                  <div class="raw-price">￥{{ plan.price }}</div>
                  立省￥{{ (plan.price * (1 - plan.discount)).toFixed(1) }}
                </div>
                <div
                  class="discount"
                  style="margin-bottom: 0.32rem"
                  v-else
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="confirm-btn-wrap">
          <div
            class="confirm-btn"
            :class="{
              advance: plans[planActIdx].skuTypeName.indexOf('高级') !== -1
            }"
            @click="confirm"
          >
            确定
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    display: {
      type: Boolean,
      default: false
    },
    plans: {
      type: Array
    }
  },
  data () {
    return {
      plan: {
        id: 0,
        label: '',
        name: '',
        price: 0,
        discount: 1,
        skuTypeName: ''
      },
      planActIdx: 0
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    planSelect (idx) {
      this.planActIdx = idx
      this.plan = this.plans[idx]
    },
    confirm () {
      this.$emit('planConfirm', this.planActIdx)
    }
  }
}
</script>
<style scoped lang="less">
.plans-mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.65);
  .containner {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    .blank {
      flex: 1;
      width: 7.5rem;
    }
    .plans-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 7.5rem;
      height: 6.5rem;
      background: #ffffff;
      .product {
        margin-top: 0.12rem;
        width: 7.02rem;
        height: 2rem;
        display: flex;
        position: relative;
        align-items: center;
        img {
          width: 1.48rem;
          height: 1.48rem;
        }
        .sku {
          margin-left: 0.18rem;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          font-size: 0.28rem;
          height: 0.9rem;
          .duration {
            font-size: 0.24rem;
            color: #c6c6c6;
          }
        }
        .close-icon-wrap {
          position: absolute;
          cursor: pointer;
          top: 0;
          right: 0;
          width: 0.8rem;
          height: 0.8rem;
          .close-icon {
            width: 0.5rem;
            height: 0.5rem;
            &::before,
            &::after {
              content: '';
              position: absolute; /*方便进行定位*/
              height: 0.35rem;
              width: 0.03rem;
              border-radius: 0.04rem;
              top: 0.1rem;
              right: 0.21rem; /*设置top和right使图像在20*20框中居中*/
              background: rgba(0, 0, 0, 0.5);
            }
            &::before {
              transform: rotate(45deg); /*进行旋转*/
            }
            &::after {
              transform: rotate(-45deg);
            }
          }
        }
      }
      .plans {
        display: flex;
        width: 7.5rem;
        align-items: center;
        background: white;
        .empty {
          min-width: 0.24rem;
          min-height: 2rem;
        }
        .plan-wrapper-mask {
          background: #53cca8;
          border-radius: 0.16rem;
          width: 7.02rem;
          height: 2.36rem;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 0px 0.04rem 0.02rem rgba(83, 204, 168, 0.2);

          &.advance {
            background: #a96c3d;
          }
          .plan-wrapper {
            flex-shrink: 0;
            white-space: nowrap;
            width: 6.98rem;
            height: 2.3rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            border-radius: 0.16rem;
            background: #ebfff9;
            position: relative;
            &.advance {
              background: #fdeed7;
            }
            .plan-logo {
              position: absolute;
              bottom: 0;
              right: 0.24rem;
            }
            .plan-label {
              width: 1.2rem;
              height: 0.38rem;
              background: #53cca8;
              border-radius: 0.04rem;
              display: flex;
              align-items: center;
              position: absolute;
              font-size: 0.28rem;
              color: #ffffff;
              right: 1.6rem;
              top: 0.3rem;
              justify-content: center;
              &.advance {
                background: #a96c3d;
              }
            }
            .plan {
              width: 3rem;
              height: 2.3rem;
              position: relative;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              background: #ebfff9;
              &.advance {
                background: #fdeed7;
              }
              border-top-left-radius: 0.16rem;
              border-bottom-left-radius: 0.16rem;
              .label {
                position: absolute;
                top: -0.01rem;
                left: -0.01rem;
                font-size: 0.2rem;
                color: #eae3ac;
                height: 0.36rem;
                padding-left: 0.12rem;
                padding-right: 0.12rem;
                background: #3c3a3a;
                display: flex;
                align-items: center;
                border-top-left-radius: 0.16rem;
                border-bottom-right-radius: 0.16rem;
              }
              .unit {
                margin-top: 0.32rem;
                font-size: 0.28rem;
                color: #000102;
              }
              .price {
                font-size: 0.44rem;
                color: #000102;
                font-weight: bold;
                margin-top: 0.24rem;
                .currency {
                  font-weight: normal;
                  font-size: 0.32rem;
                  margin-right: -0.1rem;
                }
              }
              .discount {
                margin-bottom: 0.12rem;
                display: flex;
                align-items: center;
                line-height: 100%;
                font-size: 0.2rem;
                color: #8d8d8d;
                font-weight: bold;
                letter-spacing: 0.01rem;
                .raw-price {
                  text-decoration: line-through;
                  margin-right: 0.05rem;
                }
              }
            }
          }
        }
      }
      .confirm-btn {
        cursor: pointer;
        margin-top: 0.52rem;
        width: 7.02rem;
        height: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.32rem;
        color: #ffffff;
        border-radius: 0.4rem;
        background: linear-gradient(90deg, #53ccaa 0%, #5bd88f 100%);
        &.advance {
          background: linear-gradient(90deg, #f7dda2 0%, #fac7b3 100%);
          color: #a96c3d;
        }
      }
    }
  }
}
</style>
