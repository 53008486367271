<template>
  <div id="toast" v-if="showToast">
    <div id="toast-wrap">
      <div id="message">
        {{ message }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Toast',
  props: {
    showToast: {
      // 激活
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      required: true,
    },
    delay: {
      type: Number,
      default: 3000,
    },
  },
  data() {
    return {}
  },
  methods: {
    disappear() {
      setTimeout(() => {
        this.$emit('disappear')
      }, this.delay)
    },
  },
  watch: {
    showToast() {
      this.disappear()
    },
  },
}
</script>

<style lang="less" scoped>
#toast {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100000;
  #toast-wrap {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    #message {
      background: rgb(233, 233, 235);
      padding: 0.2rem;
      border-radius: 0.05rem;
      animation: show-toast 0.2s;
      color: #909399;
      overflow: hidden;
      font-size: 0.28rem;
      z-index: 1000000;
    }
  }
}

@keyframes show-toast {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
