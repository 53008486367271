<template>
  <div class="zhimache" :class="{ advance: carType == 'advance' }">
    <div class="car-guide">
      <div class="chart">
        <div class="step">
          <div class="step-icon-wrap">
            <div class="left-line" style="visibility: hidden"></div>
            <div
              class="step-icon-outer"
              :class="{ advance: carType == 'advance' }"
            >
              <div class="step-icon">
                <img
                  v-if="carType == 'advance'"
                  style="width: 0.68rem; height: 0.26rem"
                  src="../../assets/zhima-ad.svg"
                />
                <img
                  v-else
                  style="width: 0.68rem; height: 0.26rem"
                  src="../../assets/zhima.svg"
                />
              </div>
            </div>
            <div
              class="right-line"
              :class="{ advance: carType == 'advance' }"
            ></div>
          </div>
          <div
            class="step-intro"
            :class="{ advance: carType == 'advance' }"
            style="height: 0.32rem; white-space: nowrap"
          >
            上传芝麻截图
          </div>
        </div>
        <div class="row-arrow-wrap">
          <div class="row-arrow">
            <div class="line" :class="{ advance: carType == 'advance' }"></div>
            <div class="arrow" :class="{ advance: carType == 'advance' }"></div>
          </div>
          <div class="step-intro" :class="{ advance: carType == 'advance' }">
            你看不见我
          </div>
        </div>
        <div class="step" style="margin-bottom: 0.02rem; margin-left: -0.3rem">
          <div class="step-icon-wrap">
            <div
              class="left-line"
              style="background: rgba(255, 255, 255, 0)"
            ></div>
            <div
              class="step-icon-outer"
              :class="{ advance: carType == 'advance' }"
            >
              <div class="step-icon">
                <img
                  v-if="carType == 'advance'"
                  style="width: 0.44rem; height: 0.44rem"
                  src="../../assets/review-ad.svg"
                />
                <img
                  v-else
                  style="width: 0.44rem; height: 0.44rem"
                  src="../../assets/review.svg"
                />
              </div>
            </div>
            <div
              class="right-line"
              :style="{
                background:
                  carType == 'advance' ? '#a96c3d' : 'rgba(255, 255, 255, 0.19)'
              }"
            ></div>
          </div>
          <div
            class="step-intro"
            style="height: 0.32rem; white-space: nowrap"
            :class="{ advance: carType == 'advance' }"
          >
            后台审核
          </div>
        </div>
        <div class="row-arrow-wrap">
          <div class="row-arrow">
            <div
              class="line"
              :class="{ advance: carType == 'advance' }"
              style="width: 0.24rem"
            ></div>
          </div>
          <div
            class="step-intro"
            :class="{ advance: carType == 'advance' }"
          ></div>
        </div>
        <div class="col-arrow-wrap">
          <div
            class="col-arrow"
            :style="{
              background:
                carType == 'advance' ? '#a96c3d' : 'rgba(255, 255, 255, 0.19)'
            }"
          >
            <div class="line"></div>
          </div>
          <div
            class="step-intro"
            :class="{ advance: carType == 'advance' }"
          ></div>
        </div>
        <div class="review-result">
          <div class="arrow-icon-wrap">
            <div class="row-arrow-wrap">
              <div class="row-arrow">
                <div
                  class="line"
                  :style="{
                    background:
                      carType == 'advance'
                        ? '#a96c3d'
                        : 'rgba(255, 255, 255, 0.19)'
                  }"
                  style="position: relative; width: 1.24rem"
                >
                  <span
                    :style="{
                      color: carType == 'advance' ? '#a96c3d' : '#ffffff'
                    }"
                    style="
                      font-size: 0.22rem;
                      position: absolute;
                      bottom: 0.08rem;
                      left: 0.47rem;
                    "
                    >通过</span
                  >
                </div>
                <div
                  class="arrow"
                  :class="{ advance: carType == 'advance' }"
                ></div>
              </div>
            </div>
            <div class="step-icon-wrap">
              <div
                class="step-icon-outer"
                :class="{ advance: carType == 'advance' }"
              >
                <div class="step-icon" style="position: relative">
                  <span
                    :style="{
                      color: carType == 'advance' ? '#a96c3d' : '#ffffff'
                    }"
                    style="
                      font-size: 0.22rem;
                      position: absolute;
                      color: white;
                      top: 1rem;
                      white-space: nowrap;
                    "
                    >发送家庭邀请邮件</span
                  >
                  <svg
                    t="1609937737802"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="13283"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="0.44rem"
                    height="0.44rem"
                  >
                    <path
                      d="M24.649143 399.36L965.485714 7.314286a29.257143 29.257143 0 0 1 39.643429 34.084571l-234.349714 937.472a29.257143 29.257143 0 0 1-47.104 15.36l-203.483429-169.545143a29.257143 29.257143 0 0 0-39.424 1.828572l-104.374857 104.301714a29.257143 29.257143 0 0 1-49.883429-20.626286V689.737143a29.257143 29.257143 0 0 1 8.557715-20.699429l424.448-424.448-501.101715 375.881143a29.257143 29.257143 0 0 1-36.278857-0.950857L17.188571 448.804571a29.257143 29.257143 0 0 1 7.460572-49.444571z"
                      p-id="13284"
                      :fill="carType == 'advance' ? '#a96c3d' : '#ffffff'"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="arrow-icon-wrap" style="margin-bottom: 0.36rem">
            <div class="row-arrow-wrap">
              <div class="row-arrow">
                <div
                  class="line"
                  :style="{
                    background:
                      carType == 'advance'
                        ? '#a96c3d'
                        : 'rgba(255, 255, 255, 0.19)'
                  }"
                  style="position: relative; width: 1.24rem"
                >
                  <span
                    :style="{
                      color: carType == 'advance' ? '#a96c3d' : '#ffffff'
                    }"
                    style="
                      font-size: 0.22rem;
                      position: absolute;
                      bottom: 0.08rem;
                      left: 0.32rem;
                    "
                    >不通过</span
                  >
                </div>
                <div
                  class="arrow"
                  :class="{ advance: carType == 'advance' }"
                ></div>
              </div>
            </div>
            <div class="step-icon-wrap">
              <div
                class="step-icon-outer"
                :class="{ advance: carType == 'advance' }"
              >
                <div class="step-icon" style="position: relative">
                  <span
                    :style="{
                      color: carType == 'advance' ? '#a96c3d' : '#ffffff'
                    }"
                    style="
                      font-size: 0.22rem;
                      position: absolute;
                      top: 1rem;
                      white-space: nowrap;
                    "
                    >发送邮件通知您</span
                  >
                  <span
                    :style="{
                      color: carType == 'advance' ? '#a96c3d' : '#ffffff'
                    }"
                    style="
                      font-size: 0.22rem;
                      position: absolute;
                      top: 1.34rem;
                      white-space: nowrap;
                    "
                    >联系客服重新提交截图</span
                  >
                  <svg
                    t="1609938774932"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="18352"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="0.44rem"
                    height="0.44rem"
                  >
                    <path
                      d="M682.666667 85.333333H256c-46.933333 0-85.333333 38.4-85.333333 85.333334v682.666666c0 46.933333 38.4 85.333333 85.333333 85.333334h512c46.933333 0 85.333333-38.4 85.333333-85.333334V256l-170.666666-170.666667z m-59.733334 554.666667H554.666667v170.666667c0 25.6-17.066667 42.666667-42.666667 42.666666s-42.666667-17.066667-42.666667-42.666666v-170.666667H401.066667c-38.4 0-55.466667-46.933333-29.866667-72.533333l110.933333-110.933334c17.066667-17.066667 42.666667-17.066667 59.733334 0l110.933333 110.933334c25.6 25.6 8.533333 72.533333-29.866667 72.533333z m17.066667-341.333333V128l170.666667 170.666667h-170.666667z"
                      p-id="18353"
                      :fill="carType == 'advance' ? '#a96c3d' : '#ffffff'"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="prompt"
        :style="{ color: carType == 'advance' ? '#a96c3d' : '#ffffff' }"
      >
        注意：所有家庭车均已购买会员，一加入家庭即是会员
      </div>
      <div
        class="btn-scan-zhimafen"
        :style="{ color: carType == 'advance' ? '#a96c3d' : '#5bd7bf' }"
      >
        点击头像查看车友信用分
      </div>
      <div class="car-list">
        <div
          class="car-box"
          :key="idx"
          :class="{ advance: carType == 'advance' }"
          v-for="(car, idx) in cars"
        >
          <div
            class="car-passenger-wrap"
            :key="index"
            v-for="(passenger, index) in car.passengers"
          >
            <div
              @click="
                showZhimafen({
                  zhimafen: passenger.zhimafen,
                  carId: car.carId
                })
              "
              class="avatar"
            >
              <img
                :src="require('../../assets/avatar' + (index % 5) + '.png')"
                v-if="passenger.email"
              />
              <img class="idle" src="../../assets/idle-seat.svg" v-else />
            </div>
            <div class="email" :class="{ idle: !passenger.email }">
              {{ passenger.email ? passenger.email : '空闲座位' }}
            </div>
            <div
              class="board-at"
              :class="{
                idle: !passenger.email,
                review: passenger.status == 3 || passenger.status == 7,
                advance: carType == 'advance'
              }"
            >
              {{
                passenger.status == 3
                  ? '审核中'
                  : passenger.status == 7
                  ? '驳回'
                  : passenger.status == 6
                  ? '上车中'
                  : boardTime(passenger.boardAt)
              }}
            </div>
          </div>
          <div
            @click="board(car.carId)"
            class="car-passenger-wrap"
            :style="{
              background: carType == 'advance' ? '#F5CB77' : '#5BD7BF'
            }"
            style="border-radius: 0.04rem; position: relative; height: 1.16rem"
            v-if="!car.passengers[6] || !car.passengers[6].email"
          >
            <span
              style="
                font-size: 0.28rem;
                position: absolute;
                width: 0.58rem;
                height: 0.8rem;
                color: white;
              "
              >点击上车</span
            >
            <img
              class="idle"
              src="../../assets/car.svg"
              style="
                border-radius: 0;
                position: absolute;
                top: 0.14rem;
                right: -0.08rem;
                height: 1.2rem;
                width: 1rem;
                opacity: 0.4;
              "
            />
          </div>
          <div
            class="car-passenger-wrap"
            @click="fullPrompt"
            style="
              border-radius: 0.04rem;
              position: relative;
              background: #f3f3f3;
              height: 1.16rem;
            "
            v-else
          >
            <span
              style="
                font-size: 0.28rem;
                position: absolute;
                width: 0.58rem;
                height: 0.8rem;
                color: #d5d5d5;
              "
              >已经满座</span
            >
          </div>
        </div>
      </div>
      <div class="more-cars" v-if="hasMoreCars">
        <div
          class="more-btn"
          :style="{ color: carType == 'advance' ? '#a96c3d' : '#5bd7bf' }"
          @click="moreCars"
        >
          查看更多
        </div>
      </div>
    </div>
    <loading :display="showLoading"></loading>
    <Zhimafen
      :display="zhimafenDisplay"
      @close="zhimafenDisplay = false"
      :url="passenger.zhimafenImgUrl"
    ></Zhimafen>
    <Plans
      :display="plansDisplay"
      @close="plansDisplay = false"
      @planConfirm="shangche"
      :plans="plans"
    ></Plans>
    <Toast
      :showToast="showToast"
      :message="toastMsg"
      @disappear="showToast = false"
    ></Toast>
  </div>
</template>
<script>
import { getPlans, getZhimache } from '@/api/carpool/index'
import { newOrder } from '@/api/carpool/order'
import Loading from '@/components/Loading'
import Toast from '@/components/Toast'
import Plans from '@/components/Plans'
import Zhimafen from '@/components/Zhimafen'
import { reportError } from '@/utils/log'
import { utils } from '@/utils'

export default {
  name: 'Home',
  data () {
    return {
      toastMsg: '',
      showToast: false,
      zhimafenDisplay: false,
      plansDisplay: false,
      passenger: {
        email: '',
        zhimafenImgUrl: ''
      },
      cars: [],
      zhimafenBaseUrl: utils.getStaticUrl() + 'zhimafen/',
      showLoading: false,
      curPage: 1,
      perPage: 3,
      hasMoreCars: true,
      carId: 0,
      planActIdx: 0,
      plans: [],
      carType: 'general'
    }
  },
  components: {
    loading: Loading,
    Zhimafen,
    Plans,
    Toast
  },
  watch: {
    '$route.query': async function (newV) {
      if (newV.type) {
        this.carType = newV.type
        await this.getPlans()
        this.cars = []
        this.curPage = 1
        this.getZhimache()
      }
    }
  },
  methods: {
    board (carId) {
      this.carId = carId
      this.plansDisplay = true
    },
    showZhimafen (ops) {
      if (ops.zhimafen) {
        this.passenger.zhimafenImgUrl = this.zhimafenBaseUrl + ops.zhimafen
        this.zhimafenDisplay = true
      } else {
        this.carId = ops.carId
        this.plansDisplay = true
      }
    },
    boardTime (endDate) {
      if (endDate) {
        const endDay = new Date(endDate).getTime()
        const now = new Date().getTime()
        let num = Math.floor((now - endDay) / (24 * 3600 * 1000))
        let res = ''
        if (num === 0) {
          res = '上车1天'
        } else {
          if (num < 0) {
            num = 0
          }
          res = '上车' + (num + 1) + ' 天'
        }
        return res
      }
    },
    fullPrompt () {
      this.toastMsg = '座位已满，请选择其他车'
      this.showToast = true
    },
    async getPlans () {
      try {
        this.showLoading = true
        let res = await getPlans({
          type: this.carType == 'general' ? ['芝麻车'] : ['高级芝麻车']
        })
        this.showLoading = false
        if (res.data.code == 0) {
          this.plans = res.data.plans
        }
      } catch (error) {
        reportError(error)
      }
    },
    async shangche (idx) {
      try {
        if (!this.plans.length) {
          await this.getPlans()
        }
        let orderParams = {
          skuId: this.plans[idx].id,
          uuid: localStorage.getItem('uuid'),
          cf: localStorage.getItem('cf')
        }
        if (this.carId) orderParams['carId'] = this.carId
        this.showLoading = true
        let res = await newOrder(orderParams)
        this.showLoading = false
        if (res.data.code == 0) {
          this.$router.push(
            `/carpool/shangche?orderid=${res.data.order.orderId}`
          )
        }
      } catch (error) {
        reportError(error)
      }
    },
    planSelect (idx) {
      this.planActIdx = idx
    },
    async getZhimache () {
      try {
        let res = await getZhimache({
          curPage: this.curPage,
          perPage: this.perPage,
          type: this.carType
        })
        if (res.data.code == 0) {
          if (res.data.cars.length < this.perPage) {
            this.hasMoreCars = false
          } else {
            this.hasMoreCars = true
          }
          if (res.data.cars.length) {
            this.cars = this.cars.concat(res.data.cars)
          }
        }
      } catch (error) {
        reportError(error)
      }
    },
    async moreCars () {
      this.curPage = this.curPage + 1
      this.getZhimache()
    }
  },
  async created () {
    if (this.$route.query.type) {
      this.carType = this.$route.query.type
    }
    await this.getPlans()
    this.getZhimache()
  }
}
</script>
<style lang="less" scoped>
.zhimache {
  width: 7.5rem;
  background: linear-gradient(180deg, #5bd7be 0%, #4ac1ff 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  &.advance {
    background: linear-gradient(180deg, #f7dda2 0%, #fac7b3 100%);
  }
  .car-guide {
    display: flex;
    flex-direction: column;
    width: 7.02rem;
    .chart {
      height: 3.4rem;
      display: flex;
      align-items: center;
      .step {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 1.4rem;
        .step-icon-wrap {
          width: 1.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          .left-line {
            flex: 1;
            height: 0.02rem;
            background: rgba(255, 255, 255, 0.19);
            &.advance {
              background: rgba(169, 108, 61, 0.19);
            }
          }
          .step-icon-outer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 0.96rem;
            height: 0.96rem;
            border-radius: 50%;
            border: 0.02rem solid rgba(255, 255, 255, 0.45);
            &.advance {
              border: 0.02rem solid #a96c3d;
            }
            .step-icon {
              width: 0.88rem;
              height: 0.88rem;
              border-radius: 50%;
              background: rgba(255, 255, 255, 0.19);
              &.advance {
                background: #a96c3d;
              }
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .right-line {
            flex: 1;
            height: 0.02rem;
            background: rgba(255, 255, 255, 0.19);
            &.advance {
              background: #a96c3d;
            }
          }
        }
        .step-intro {
          display: flex;
          align-items: center;
          text-align: center;
          margin-top: 0.04rem;
          font-size: 0.22rem;
          color: white;
          height: 0.64rem;
          &.advance {
            color: #a96c3d;
          }
        }
      }
      .row-arrow-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        .row-arrow {
          height: 0.96rem;
          display: flex;
          align-items: center;
          .line {
            width: 0.8rem;
            height: 0.02rem;
            background: rgba(255, 255, 255, 0.19);
            &.advance {
              background: #a96c3d;
            }
          }
          .arrow {
            border: 0.1rem solid transparent;
            border-left: 0.2rem solid rgba(255, 255, 255, 0.19);
            &.advance {
              border-left: 0.2rem solid #a96c3d;
            }
            width: 0;
            height: 0px;
          }
        }
        .step-intro {
          margin-top: 0.04rem;
          font-size: 0.22rem;
          color: white;
          visibility: hidden;
          height: 0.32rem;
          &.advance {
            color: #a96c3d;
          }
        }
      }
      .col-arrow-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        .col-arrow {
          display: flex;
          align-items: center;
          height: 1.64rem;
          width: 0.02rem;
          background: rgba(255, 255, 255, 0.19);
        }
        .step-intro {
          margin-top: 0.04rem;
          font-size: 0.22rem;
          color: white;
          visibility: hidden;
          height: 0.32rem;
          &.advance {
            color: #a96c3d;
          }
        }
      }
      .review-result {
        display: flex;
        height: 2.96rem;
        flex-direction: column;
        justify-content: space-between;
        .arrow-icon-wrap {
          display: flex;
          align-items: center;
          height: 0.96rem;
          .row-arrow-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            .row-arrow {
              height: 0.96rem;
              display: flex;
              align-items: center;
              .line {
                width: 1rem;
                height: 0.02rem;
                background: rgba(255, 255, 255, 0.19);
                color: #ffffff;
                &.advance {
                  color: #a96c3d;
                }
              }
              .arrow {
                border: 0.1rem solid transparent;
                border-left: 0.2rem solid rgba(255, 255, 255, 0.19);
                &.advance {
                  border-left: 0.2rem solid #a96c3d;
                }
                width: 0;
                height: 0px;
              }
            }
          }
          .step-icon-outer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 0.96rem;
            height: 0.96rem;
            border-radius: 50%;
            border: 0.02rem solid rgba(255, 255, 255, 0.45);
            &.advance {
              border: 0.02rem solid #a96c3d;
            }
            margin-left: -0.1rem;
            .step-icon {
              width: 0.88rem;
              height: 0.88rem;
              border-radius: 50%;
              background: rgba(255, 255, 255, 0.19);
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
    .prompt {
      margin-top: 0.34rem;
      font-size: 0.24rem;
      color: white;
    }
    .btn-scan-zhimafen {
      font-size: 0.24rem;
      background: white;
      border-radius: 0.04rem;
      width: 2.84rem;
      height: 0.58rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.14rem;
      font-weight: 500;
    }
    .car-list {
      margin-top: 0.24rem;
      width: 7.02rem;
      display: flex;
      flex-direction: column;
      .car-box {
        margin-bottom: 0.16rem;
        display: flex;
        flex-shrink: 0;
        flex-wrap: wrap;
        align-content: space-around;
        background: white;
        width: 7.02rem;
        height: 4.6rem;
        border-radius: 0.08rem;
        box-shadow: 0px 0px 0.08rem 0px #24a99b;
        &.advance {
          box-shadow: 0px 0px 0.08rem 0px #a96c3d;
        }
        .car-passenger-wrap {
          width: 1.16rem;
          display: flex;
          margin-left: 0.54rem;
          flex-direction: column;
          cursor: pointer;
          &:first-child,
          &:nth-child(5) {
            margin-left: 0.38rem;
          }
          align-items: center;
          justify-content: center;
          .avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f3f3f3;
            width: 1.16rem;
            height: 1.16rem;
            border-radius: 50%;
            img {
              width: 1.16rem;
              height: 1.16rem;
              border-radius: 50%;
              &.idle {
                width: 0.7rem;
                height: 0.7rem;
                cursor: pointer;
              }
            }
          }
          .email {
            font-size: 0.2rem;
            color: #333333;
            height: 0.38rem;
            width: 1.14rem;
            margin-top: 0.08rem;
            display: flex;
            align-items: center;
            overflow-y: hidden;
            overflow-x: auto; // 没有下面那个值的时候，就取这个做兼容
            overflow-x: overlay; //这个值不会占用滚动条的空间
            text-overflow: ellipsis; //用省略号显示
            &::-webkit-scrollbar {
              width: 0 !important;
            }
            white-space: nowrap; //不换行
            &.idle {
              color: #b0b0b0;
              justify-content: center;
            }
          }
          .board-at {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #5ad6be;
            &.advance {
              background: #f5cb77;
            }
            border-radius: 0.04rem;
            font-size: 0.2rem;
            color: white;
            width: 1.1rem;
            height: 0.32rem;
            &.idle {
              visibility: hidden;
            }
            &.review {
              background: #4ac1ff;
            }
            &.advance.reivew {
              background: #f37e74;
            }
          }
        }
      }
    }
    .more-cars {
      margin-top: 0.22rem;
      margin-bottom: 0.38rem;
      width: 7.02rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .more-btn {
        width: 2.1rem;
        height: 0.7rem;
        background: #ffffff;
        border-radius: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5bd7bf;
        font-size: 0.28rem;
      }
    }
  }
}
</style>
